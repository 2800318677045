// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB0bInWv2Dbi4hYg3BVfC10NFkdKv9jWGs",
  authDomain: "andriy-footbsll-hes.firebaseapp.com",
  databaseURL: "https://andriy-footbsll-hes-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "andriy-footbsll-hes",
  storageBucket: "andriy-footbsll-hes.appspot.com",
  messagingSenderId: "1005954799018",
  appId: "1:1005954799018:web:ddf5f19a1756bacbfb87d3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;