
import './App.css';
import { useEffect, useState} from "react";
import NavBar from "./components/navbar";
import Table from "./components/table";
import Login from "./components/login";
import Text from "./components/text";
import Ptable from "./components/ptable";
import Pfixture from "./components/pfixture";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Fixture from "./components/fixture";
import { fetchFixtures } from "./lib/fetch-data";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import ReactGA from "react-ga4";
import OneSignal from 'react-onesignal';
ReactGA.initialize("G-HY21WSX2NG");


function App() {
  useEffect(() => {
    OneSignal.init({
      appId: "4c1eab44-442f-4b78-939c-acd98478500b"
    });
  }, []);
const [fixtures, setFixtures] = useState([]);
   const fetchInfo = async () => {
     const data = await fetchFixtures();
     setFixtures(data);
     };
    useEffect(() => {
   fetchInfo() 
 }, [fixtures]);
 // Send pageview with a custom path
ReactGA.send({ hitType: "pageview", page: "/", title: "Match Listing" });
  return (
    <div className="">
      <NavBar />
      {fixtures.length === 0 ? (
        <div className="h-screen bg-white w-full text-center p-10">
          <Box>
            <CircularProgress />
          </Box>
        </div>
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/"  element={<Table data={fixtures}  />}></Route>
            <Route path="/ptable"  element={<Ptable data={fixtures}  />}></Route>
            <Route
            
              path="/fixture/:matchID"
              element={<Fixture data={fixtures} />}
               >

               </Route>

               <Route
            
            path="/pfixture/:matchID"
            element={<Pfixture data={fixtures} />}
             >
              
             </Route>
               <Route path="/login" element={<Login />} ></Route>
               <Route path="/text"  element={<Text />}></Route>
            </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}
export default App;
