export const fetchFixtures = async () => {
  const options = {
    method: "POST",  
    
  };

  const result = await fetch(
    "https://corsproxy.io/?https://bettystore.store/match.json",
    options
    
  )
    .then((response) => response.json())
    .then((response) => {
      return response;
    })
    .catch((err) => console.error("no network please connect to a network and refresh"));

  return result;
};
