import React, { useState, useEffect } from 'react';
import app from "../firebaseConfig";
import { getDatabase, ref, get, orderByChild, query, equalTo } from "firebase/database";
import { useNavigate, useLocation, useHistory  } from 'react-router-dom';
import Swal from 'sweetalert2'

export default function Login() {
    const [email, setEmail] = useState('');
    const history = useNavigate();
    const location = useLocation();
    const histoire = useNavigate();
    const five = email.substr(email.length -5);
    useEffect(() => {
      // The path that the user is supposed to go to
      const destinationPath = location.pathname;
  
      // If our site was opened in a new window or tab and accessed directly
      // OR the page before this one was NOT a page on our site, then...
      if (
        document.referrer === window.location.href ||
        document.referrer.indexOf(window.location.host) === -1
      ) {
        // Replaces the current pathname with the homepage
        histoire("/login");
  
        // Then pushes to the path the user was supposed to go to
        histoire(destinationPath);
      }
    }, []);
    
    function setCookie(c_name,value,exdays){var exdate=new Date();exdate.setDate(exdate.getDate() + exdays);var c_value=escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());document.cookie=c_name + "=" + c_value;}

    function getCookie(c_name){var c_value = document.cookie;var c_start = c_value.indexOf(" " + c_name + "=");if (c_start == -1){c_start = c_value.indexOf(c_name + "=");}if (c_start == -1){c_value = null;}else{c_start = c_value.indexOf("=", c_start) + 1;var c_end = c_value.indexOf(";", c_start);if (c_end == -1){c_end = c_value.length;}c_value = unescape(c_value.substring(c_start,c_end));}return c_value;}
    
    
    
    function checkSession(){
       var c = getCookie("visited");
       if (c === "yes") {
        
       } else {
        Swal.fire({
          title: "YOUR FREE TRIAL PERIOD HAS EXPIRED",
          text: "Please subscribe to watch all Live Football matches",
          imageUrl: "https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png",
        imageWidth: 100,
      imageHeight: 100,
      imageAlt: "Custom image",
          showCancelButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          closeOnClickOutside: false,
          confirmButtonColor: 'red',
          confirmButtonText: 'CLICK TO SUBSCRIBE'
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "https://bettystore.store/live-football-tv-hd/"
          }
        })
       }
       setCookie("visited", "yes", 365); // expire in 1 year; or use null to never expire
    }
    
  useEffect(() => {
    checkSession()
  }, []);
    
  const handleSubmit = (e) => {
   
   e.preventDefault();
      const db = getDatabase(app);
      const dbRef = ref(db, "USERS");

      const qry = query(dbRef, orderByChild("phone_number"), equalTo(five));
      get(qry).then((snapshot) => {
    if (snapshot.exists()) {
        
  
      histoire("/ptable");
       
          } else {
            Swal.fire({
              title: "NO SUBSCRIPTION FOUND/SUBSCRIPTION EXPIRED ",
              text: "Please subscribe to watch all Live Football matches",
              imageUrl: "https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png",
            imageWidth: 100,
          imageHeight: 100,
          imageAlt: "Custom image",
              showCancelButton: false,
              confirmButtonColor: 'red',
              confirmButtonText: 'CLICK TO SUBSCRIBE'
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = "https://bettystore.store/live-football-tv-hd/"
              }
            })
       
          }
          });

      
    };

    return (
        <section className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0">
          
            <form 
            
            
            className="h-screen flex flex-col md:flex-row justify-center space-y-10 md:space-y-0 md:space-x-16 items-center my-2 mx-5 md:mx-0 md:my-0"
            onSubmit={handleSubmit}>
        <div className="md:w-1/3 max-w-sm justify-items-center"
        >
        
          <img
            src="https://betisfootball.online/wp-content/uploads/2024/08/oreva-signup_image.png"
            alt="Sample image" />
            
        </div>
        <div className="md:w-96 max-w-sm">
        <label className="mr-1 mb-px text-base ">Already a subscriber? Sign in with mobile number</label>
          <div className="text-center md:text-left">
          
          </div>
        
          <input className="text-sm w-full px-4 py-2 border border-solid border-gray-300 rounded" type="text" placeholder="Phone Number" value={email}
          onChange={(e) => setEmail(e.target.value)} />
         
          <div className="text-center md:text-left">
            <button
               class="nav-button hover:drop-shadow-lg] flex w-full items-center justify-center rounded-full border border-[#072C48] bg-[#072C48] bg-gradient-to-tr from-[#0767AC] to-[#0767AC]/70 px-7 py-2.5 text-white font-bold text-white-800 ring-lime-600 ring-offset-2 ring-offset-white-700 drop-shadow-[0px_1px_2px_rgb(0,0,0,0.3)] active:ring-1 mt-4">
            
               Log in</button>
          </div>
          <div className="mt-4 font-semibold text-base text-slate-500 text-center md:text-left">
            Not yet a Subscriber? <a className="text-red-600 hover:underline hover:underline-offset-4" href="https://bettystore.store/live-football-tv-hd/">Click to Subscribe</a>
          </div>


          <div className="mt-10 font-semibold text-base text-slate-500 text-center md:text-left justify-items-end">
            Need Help? <a className="text-red-600 hover:underline hover:underline-offset-4" href="https://api.whatsapp.com/send?phone=2347038872360">Click to Chat Support</a>
          </div>
         
          <div class="flex flex-wrap justify-center">
          
            
        </div>
        </div>
        
        </form>
      </section>
      );
     }
