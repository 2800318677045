import React from "react";

export default function NavBar() {
  return (
    <>
     <div className="navbar bg-base-100" data-theme="dark">
  <a href="/" className="btn btn-ghost normal-case text-xl">FootballTv</a>
</div>
    </>
  );
}