import React, { useState, useEffect } from 'react';
const Text = () => {
    const [pageViews, setPageViews] = useState(0);

    useEffect(() => {
      const storedPageViews = localStorage.getItem('pageViews');
  
      if (storedPageViews) {
        setPageViews(parseInt(storedPageViews, 10));
      }
  
      setPageViews((prevPageViews) => {
        const newPageViews = prevPageViews + 1;
        localStorage.setItem('pageViews', newPageViews.toString());
        return newPageViews;
      });
    }, []);
  
    return (
      <div>
        <h1>Page View Counter</h1>
        <p>This page has been viewed {pageViews} times.</p>
      </div>
    );

};

export default Text