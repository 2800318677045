import React, { useEffect, useState } from "react";
import ReactPlayer from 'react-player';


import { useLocation, useParams, useNavigate } from "react-router-dom";

 export default function Pfixture({data}) {

  const {matchID} = useParams()
  const thisProduct = data.find(fixture => fixture.user=== matchID)

 
     return (
      <div className="player-wrapper bg-black">
      <ReactPlayer
        width="100vw"
        url={thisProduct.pageUrl}
        height="85vh"
        controls 
        playing = {true}
          >
         </ReactPlayer>
       </div>
    )
     
}